import { getDetail, submit } from '@/api/plugin/workflow/draft'

export default {
  methods: {
    initDraft(processDefId) {
      return new Promise((resolve) => {
        getDetail({ processDefId, platform: 'pc' }).then(res => {
          const { data } = res.data
          if (data && data.variables && Object.keys(data.variables).length > 0) resolve(data.variables)
        })
      })
    },
    handleDraft(processDefId, formKey, variables) {
      this.$confirm(this.$t('cip.plat.wfOps.process.msg.drafts'), this.$t('cip.cmn.title.promptTitle'), {
        type: 'warning'
      }).then(() => {
        submit({ processDefId, formKey, variables: JSON.stringify(variables), platform: 'pc' }).then(() => {
          this.handleCloseTag('/plugin/workflow/process/start')
        })
      }).catch(() => {
      })
    }
  }
}